import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import useCustomRouter from '@/hooks/useCustomRouter';
import useAccount from '@/lib/hooks/useAccount';
import useBusinessUnits from '@/lib/hooks/useBusinessUnits';
import useCart from '@/lib/hooks/useCart';
import useQuotes from '@/lib/hooks/useQuotes';
import useStores from '@/lib/hooks/useStores';
import { Quote } from '@shared/types/quote/Quote';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';
import { useDebounce } from '@/hooks/useDebounce';
import useProductSearch from '@/lib/hooks/useProductSearch';
import toast from 'react-hot-toast';
import { mapBusinessUnit } from '@/utils/mappers/map-business-unit';
import { mapStore } from '@/utils/mappers/map-store';
import { mapCsvProduct } from '@/utils/mappers/map-csv-product';
import { BusinessUnit } from '@shared/types/business-unit';
import { Store } from '@shared/types/store';
import useSwrClearCache from '@/hooks/useSwrClearCache';
import { mapProductSuggestion } from '@/utils/mappers/map-product-suggestion';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { useCustomObjects } from '@/lib/hooks/useCustomObjects';
import { ChannelVisibility } from '@shared/types/custom-object/ChannelsVisibilitySettings';

const useHeaderData = () => {
  const router = useCustomRouter();
  const clearCache = useSwrClearCache();
  const { translate } = useTranslation();
  const searchParams = useSearchParams();
  const searchQuery = searchParams.get('query');

  const { defaultBusinessUnit, businessUnits } = useBusinessUnits();
  const { defaultStore, stores, channel, priceChannel } = useStores();

  const { account, logout, updateSessionData, businessUnitKey: sessionBusinessUnitKey } = useAccount();
  const { selectedBusinessUnit, selectedStore, setSelectedBusinessUnit, setSelectedStore } = useStoreAndBusinessUnits();

  const customObjectChannelsVisibility = useCustomObjects('channelsVisibilitySettings', 'channels-visibility-list');
  const channelsVisibilitySettings: ChannelVisibility[] = customObjectChannelsVisibility?.data?.value ?? [];
  const channelsVisibilityKeys = channelsVisibilitySettings.map((item) => item.key);

  const onBusinessUnitSelect = useCallback(
    async (businessUnitKey: string, reload = false) => {
      if (businessUnitKey === selectedBusinessUnit?.key && businessUnitKey === sessionBusinessUnitKey) return;

      const bu = businessUnits.find((bu) => bu.key === businessUnitKey) as BusinessUnit;

      if (!bu) return;
      setSelectedBusinessUnit(mapBusinessUnit(bu));

      const st = bu?.stores?.find((st) => st.key === bu.key) as Store;

      if (st) {
        if (st.key === selectedStore?.key) return;
        setSelectedStore(mapStore(st));
        localStorage.setItem('store', st.key ?? '');

        const distributionChannel = st.distributionChannels?.find((channel) =>
          channel.key ? !channelsVisibilityKeys.includes(channel.key) : undefined,
        );
        const customerCategoryChannel = st.distributionChannels?.find((channel) =>
          channel.key ? channelsVisibilityKeys.includes(channel.key) : undefined,
        );

        if (
          distributionChannel &&
          customerCategoryChannel &&
          distributionChannel.channelId &&
          customerCategoryChannel.channelId &&
          bu.key &&
          st.storeId
        ) {
          await updateSessionData(
            bu.key,
            st.key,
            st.storeId,
            distributionChannel.channelId,
            customerCategoryChannel.channelId,
            customerCategoryChannel.key ?? '',
          );

          if (reload) {
            router.replace('/');
            router.refresh();
            toast.success(translate('common.businessUnit.connected', { values: { name: bu.name ?? '' } }), {
              position: 'top-center',
              duration: 4500,
              style: {
                height: 'auto',
              },
            });
          }
        }
      }

      localStorage.setItem('business-unit', bu.key as string);
    },
    [selectedBusinessUnit, businessUnits, setSelectedBusinessUnit, updateSessionData, router, translate],
  );

  useEffect(() => {
    const storedBusinessUnitKey = localStorage.getItem('business-unit') ?? defaultBusinessUnit?.key;

    const effectiveBusinessUnitKey = selectedBusinessUnit?.key ?? storedBusinessUnitKey;

    if (!effectiveBusinessUnitKey) return;

    const shouldUpdateBusinessUnit =
      !selectedBusinessUnit ||
      effectiveBusinessUnitKey !== selectedBusinessUnit.key ||
      effectiveBusinessUnitKey !== sessionBusinessUnitKey;

    if (shouldUpdateBusinessUnit) {
      const bu = businessUnits.find((bu) => bu.key === effectiveBusinessUnitKey) ?? defaultBusinessUnit;
      if (bu && bu.key) {
        onBusinessUnitSelect(bu.key, false);
      }
    }
  }, [selectedBusinessUnit]);

  const { totalItems: totalCartItems, addItem } = useCart(selectedBusinessUnit?.key, selectedStore?.key);

  const { quotes } = useQuotes({ businessUnitKey: selectedBusinessUnit?.key ?? '' });
  const quotesMapped = useMemo(() => quotes?.items?.filter((quote: Quote) => quote.quoteState === 'Pending'), [quotes]);

  const onLogoutClick = useCallback(() => {
    logout().then(() => {
      clearCache();
      localStorage.clear();
      router.refresh();
      router.push('/login');
    });
  }, [logout, clearCache, router]);

  const [quickOrderSearch, setQuickOrderSearch] = useState('');
  const debouncedQuickOrderSearch = useDebounce(quickOrderSearch, 150);
  const shouldFetchQuickOrder = !!(debouncedQuickOrderSearch && selectedStore?.key);
  const { products: quickOrderProducts } = useProductSearch(
    shouldFetchQuickOrder ? debouncedQuickOrderSearch : undefined,
    undefined,
    undefined,
    selectedStore?.key,
    channel?.key,
    priceChannel?.channelId,
    shouldFetchQuickOrder ? true : undefined,
  );

  const onQuickOrderSearch = useCallback((value: string) => setQuickOrderSearch(value), []);

  const [headerSearch, setHeaderSearch] = useState(searchQuery || '');
  const debouncedHeaderSearch = useDebounce(headerSearch, 150);

  const shouldFetchHeaderSearch = !!(selectedStore?.key && debouncedHeaderSearch !== '');
  const { products: headerProducts } = useProductSearch(
    shouldFetchHeaderSearch ? debouncedHeaderSearch : '',
    undefined,
    undefined,
    selectedStore?.key,
    channel?.key,
    priceChannel?.channelId,
    shouldFetchHeaderSearch ? true : undefined,
  );

  const searchSuggestions = useMemo(() => {
    if (debouncedHeaderSearch === '') {
      return [];
    }
    return headerProducts.map(mapProductSuggestion);
  }, [headerProducts, debouncedHeaderSearch]);

  const onHeaderSearch = useCallback((value: string) => setHeaderSearch(value), []);
  const headerSearchAction = useCallback(
    () => router.push(`/search/?query=${debouncedHeaderSearch}`),
    [router, debouncedHeaderSearch],
  );

  const [skus, setSKUs] = useState<string[] | undefined>([]);
  const shouldFetchCsvProducts = !!(selectedStore?.key && skus?.length);
  const { products: csvShowProducts } = useProductSearch(
    shouldFetchCsvProducts ? '' : undefined,
    shouldFetchCsvProducts ? skus : undefined,
    shouldFetchCsvProducts ? skus.length : undefined,
    selectedStore?.key,
    channel?.key,
    priceChannel?.channelId,
    shouldFetchCsvProducts ? true : undefined,
  );

  const handleSKUsUpdate = useCallback((skus: string[]) => setSKUs(skus), []);
  const mappedCsvProducts = useMemo(() => csvShowProducts.map(mapCsvProduct), [csvShowProducts]);

  const mappedStores =
    stores?.map((store) => ({ name: store.name ?? store.key ?? '-', value: store.key ?? '-' })) ?? [];

  const mappedBusinessUnits =
    businessUnits?.map((businessUnit) => ({
      name: businessUnit.name ?? businessUnit.key ?? '-',
      value: businessUnit.key ?? '-',
    })) ?? [];

  return {
    account,
    defaultBusinessUnit,
    selectedStore: selectedStore?.key ?? defaultStore?.key,
    stores: mappedStores,
    selectedBusinessUnit: selectedBusinessUnit?.key ?? defaultBusinessUnit?.key,
    businessUnits: mappedBusinessUnits,
    totalCartItems,
    quotes: quotesMapped,
    headerSearch,
    searchSuggestions,
    quickOrderSearch,
    quickOrderProducts,
    csvShowProducts: mappedCsvProducts,
    addToCart: addItem,
    onBusinessUnitSelect,
    // onStoreSelect,
    onHeaderSearch,
    onQuickOrderSearch,
    headerSearchAction,
    onLogoutClick,
    handleSKUsUpdate,
  };
};

export default useHeaderData;
