'use client';

import React from 'react';
import Button from '@/components/atoms/button';
import Typography from '@/components/atoms/typography';
import Image from '@/components/atoms/Image';
import { PlusIcon } from '@heroicons/react/24/outline';
import useCustomRouter from '@/hooks/useCustomRouter';
import { HighlightBannerProps } from './types';

const HighlightBanner = ({ title, description, image, link, linkLabel }: HighlightBannerProps) => {
  const router = useCustomRouter();

  const handleClick = () => {
    if (link.openInNewTab) {
      window.open(link.href, '_blank', 'noopener,noreferrer');
    } else {
      router.push(link.href);
    }
  };
  return (
    <div className="relative flex h-[300px] w-full items-center bg-cover bg-center">
      <Image {...image} alt={title} fill className="absolute inset-0" />

      <div className="z-10 ml-64 max-w-2xl">
        <Typography classStyle="h3" className="mb-2">
          {title}
        </Typography>
        <Typography classStyle="b" className="mb-4">
          {description}
        </Typography>
        <Button
          variant="tertiary"
          icon={<PlusIcon className="thick-svg ml-1 w-6 text-white-100" />}
          onClick={handleClick}
        >
          {linkLabel}
        </Button>
      </div>
    </div>
  );
};

export default HighlightBanner;
